// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-list ul {
    padding: 0;
    margin: 0;
}

.blog-header {
    color: #333;
    transition: color 0.3s ease;
}

.dark-mode .blog-header {
    color: #fff;
}

.post-item {
    list-style-type: none;
    margin-bottom: 0.5rem;
}

.blog-post-link {
    text-decoration: none;
    padding: 0.5rem 1rem;
    border: 2px solid #28282B;
    border-radius: 10px;
    display: inline-block;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    color: #333;
}

.blog-post-link:hover {
    border-color: #0275D8FF;
    background-color: #28282B;
    color: #FFFFFF;
    transform: scale(1.05);
}

.dark-mode .blog-post-link {
    color: #fff;
    border: 2px solid #f0a500;
}

.dark-mode .blog-post-link:hover {
    border-color: #f0a500;
    background-color: #343a40;
    color: #f0a500;
    transform: scale(1.05);
}
`, "",{"version":3,"sources":["webpack://./src/styles/blogcategorypostslist.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;IACpB,yBAAyB;IACzB,mBAAmB;IACnB,qBAAqB;IACrB,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,cAAc;IACd,sBAAsB;AAC1B","sourcesContent":[".post-list ul {\n    padding: 0;\n    margin: 0;\n}\n\n.blog-header {\n    color: #333;\n    transition: color 0.3s ease;\n}\n\n.dark-mode .blog-header {\n    color: #fff;\n}\n\n.post-item {\n    list-style-type: none;\n    margin-bottom: 0.5rem;\n}\n\n.blog-post-link {\n    text-decoration: none;\n    padding: 0.5rem 1rem;\n    border: 2px solid #28282B;\n    border-radius: 10px;\n    display: inline-block;\n    font-size: 1.2rem;\n    transition: all 0.3s ease;\n    color: #333;\n}\n\n.blog-post-link:hover {\n    border-color: #0275D8FF;\n    background-color: #28282B;\n    color: #FFFFFF;\n    transform: scale(1.05);\n}\n\n.dark-mode .blog-post-link {\n    color: #fff;\n    border: 2px solid #f0a500;\n}\n\n.dark-mode .blog-post-link:hover {\n    border-color: #f0a500;\n    background-color: #343a40;\n    color: #f0a500;\n    transform: scale(1.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
