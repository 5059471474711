// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animated-object-wrapper {
    position: absolute;
    top: 0px;
}


.animated-object {
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: #343a40;
    animation: float 5s ease-in-out infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border: 2px solid #6c757d;
}

@keyframes float {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(100px, 100px);
    }
    100% {
        transform: translate(0, 0);
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/QueuedSquares.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;AACZ;;;AAGA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,wCAAwC;IACxC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,kCAAkC;IACtC;IACA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".animated-object-wrapper {\n    position: absolute;\n    top: 0px;\n}\n\n\n.animated-object {\n    position: absolute;\n    width: 150px;\n    height: 150px;\n    background-color: #343a40;\n    animation: float 5s ease-in-out infinite;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 1.2rem;\n    font-weight: bold;\n    color: #fff;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0,0,0,0.5);\n    border: 2px solid #6c757d;\n}\n\n@keyframes float {\n    0% {\n        transform: translate(0, 0);\n    }\n    50% {\n        transform: translate(100px, 100px);\n    }\n    100% {\n        transform: translate(0, 0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
