// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-post-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
}

.blog-post-add-widget-light,
.blog-post-add-widget-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    min-height: 300px;
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    padding: 10px;
    flex-grow: 1;
}

.blog-post-add-widget-light {
    background-color: black;
    border: 2px solid #3B71CA;
}

.blog-post-add-widget-dark {
    background-color: #343a40;
    border: 2px solid #E4A11B;
}

.blog-post-add-widget-light:hover,
.blog-post-add-widget-dark:hover {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
    background-color: #343a40;
}

.widget-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/styles/blogpostcategorywidget.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,aAAa;AACjB;;AAEA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;IACnB,0CAA0C;IAC1C,eAAe;IACf,gCAAgC;IAChC,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;;IAEI,iDAAiD;IACjD,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,WAAW;AACf","sourcesContent":[".blog-post-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    height: 100vh;\n}\n\n.blog-post-add-widget-light,\n.blog-post-add-widget-dark {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 300px;\n    min-height: 300px;\n    margin: 10px;\n    border-radius: 20px;\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n    transition: all 0.3s ease-in-out;\n    text-align: center;\n    padding: 10px;\n    flex-grow: 1;\n}\n\n.blog-post-add-widget-light {\n    background-color: black;\n    border: 2px solid #3B71CA;\n}\n\n.blog-post-add-widget-dark {\n    background-color: #343a40;\n    border: 2px solid #E4A11B;\n}\n\n.blog-post-add-widget-light:hover,\n.blog-post-add-widget-dark:hover {\n    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);\n    background-color: #343a40;\n}\n\n.widget-title {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
