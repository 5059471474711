// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #343a40;
    color: #fff;
    padding: 0px;
    width: 100%;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,eAAe;IACf,SAAS;AACb","sourcesContent":["footer {\n    background-color: #343a40;\n    color: #fff;\n    padding: 0px;\n    width: 100%;\n}\n\n.fixed-bottom {\n    position: fixed;\n    bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
